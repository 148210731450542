import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    fallbackLng: "gu",
    lng: "gu",
    returnObjects: true,
    resources: {
        en: {
            translation:{
                home: "Home",
                positivity: "Positivity",
                switchTo: "Switch To",
                aboutUs: "About Us",
                positiveNews: "Positive News",
                parmarth: "Inspire Stories",
                purusharth: "Ideal Stories",
                contact: "Contact",
                us: "Us",
                latest: "Latest",
                latestDesc: "In this section we bring stories of individuals who struggle and work hard for their family",
                gata: "Gata",
                gam: "Gam",
                footerDesc: "Enjoy reading stories of positivity, compassion and connection from around the world",
                category: "Category",
                tags: "Tags",
                quickContact: "Quick contact",
                videos:"Videos",
                stories:"Stories",
                advertisement:"Advertisement",
                privacyPolicy:"Privacy Policy",
                termsAndConditions:"Terms And Conditions",
                about: {
                    line1:"For the past 10+ years  SSINEX has a mission to share knowledge with professional learners.",
                    line2:"We are providing Digital Learning contents with online platform and Offering all types of digital \nsolutions at one place Right consultancy from experts consultant\n",
                    line3:" is a portal with only positive contents: videos, news and many more",
                    line4:"Let's join together in our mission of spreading positivity across the world. All the best!",
                },
                comment: "Give Your Feedback",
                name: "Name",
                email: "Email",
                mobile: "Mobile",
                message: "Message",
                submit: "Submit",
                readMore: "Read More",
                purushrthaStories: "Ideal Stories",
                parmarthStories: "Inspire Stories",
                samachar: "News",
            },
        },
        hi: {
            translation:{
                home: "घर",
                positivity: "सकारात्मकता",
                switchTo: "स्विच करें",
                aboutUs: "हमारे बारे में",
                positiveNews: "पॉजिटिव समाचार",
                parmarth: "प्रेरणादायक कहानिया",
                purusharth: "आदर्श कहानिया",
                contact: "संपर्क",
                us: "करें",
                latest: "नवीनतम",
                latestDesc: "इस अनुभाग में हम ऐसे व्यक्तियों की कहानियाँ लाते हैं जो अपने परिवार के लिए संघर्ष करते हैं और कड़ी मेहनत करते हैं",
                gata: "गता",
                gam: "गम",
                footerDesc: "दुनिया भर से सकारात्मकता, करुणा और जुड़ाव की कहानियाँ पढ़ने का आनंद लें",
                category: "वर्ग",
                tags: "टैग",
                quickContact: "त्वरित संपर्क करें",
                videos:"वीडियो",
                stories:"कहानियों",
                advertisement:"विज्ञापन",
                privacyPolicy:"गोपनीयता नीति",
                termsAndConditions:"नियम और शर्तें",
                about: {
                    line1:"पिछले 10+ वर्षों से SSINEX का मिशन पेशेवर शिक्षार्थियों के साथ ज्ञान साझा करना है।",
                    line2:"हम ऑनलाइन प्लेटफॉर्म के साथ डिजिटल लर्निंग सामग्री प्रदान कर रहे हैं और एक ही स्थान पर सभी प्रकार के डिजिटल समाधान पेश कर रहे हैं, \nविशेषज्ञ सलाहकार से सही सलाह ले रहे हैं\n",
                    line3:" एक पोर्टल है जिसमें केवल सकारात्मक सामग्री है: वीडियो, समाचार और बहुत कुछ।",
                    line4:"आइए दुनिया भर में सकारात्मकता फैलाने के हमारे मिशन में एक साथ शामिल हों। शुभकामनाएं!",
                },
                comment: "आपकी प्रतिक्रिया दीजिये",
                name: "नाम",
                email: "ईमेल",
                mobile: "मोबाईल",
                message: "संदेश",
                submit: "सब्मिट",
                readMore: "और पढ़ें",
                purushrthaStories: "आदर्श कहानिया",
                parmarthStories: "प्रेरणादायक कहानिया",
                samachar: "समाचार",
            },
        },
        gu: {
            translation:{
                home: "ઘર",
                positivity: "પોઝિટિવિટી",
                switchTo: "ભાષા બદલો",
                aboutUs: "અમારા વિષે",
                positiveNews: "પોઝિટિવ સમાચાર",
                parmarth: "પ્રેરણાદાયક વાર્તાઓ",
                purusharth: "આદર્શ વાર્તાઓ",
                contact: "સંપર્ક",
                us: "કરો",
                latest: "નવીનતમ",
                latestDesc: "આ વિભાગમાં અમે એવા વ્યક્તિઓની વાર્તાઓ લાવ્યા છીએ જેઓ તેમના પરિવાર માટે સંઘર્ષ અને સખત મહેનત કરે છે",
                gata: "ગતા",
                gam: "ગમ",
                footerDesc: "અમે વિશ્વભરમાંથી સકારાત્મકતા શું, પરમર્થ અને સંબંધાર્થની વાર્તાઓ વાંચીને આનંદ અનુભવો",
                category: "કેટેગરી",
                tags: "ટૅગ્સ",
                quickContact: "ઝડપી સંપર્ક",
                videos:"વીડિયો",
                stories:"વાર્તાઓ",
                advertisement:"જાહેરાત",
                privacyPolicy:"પ્રિવેર્સી પોલિસી",
                termsAndConditions:"શરતો અને નિયમો",
                about: {
                    line1:"છેલ્લા 10+ વર્ષોથી SSINEX વ્યાવસાયિક શીખનારાઓ સાથે જ્ઞાન વહેંચવાનું મિશન ધરાવે છે.",
                    line2:"અમે ઓનલાઈન પ્લેટફોર્મ સાથે ડિજિટલ લર્નિંગ કન્ટેન્ટ પ્રદાન કરીએ છીએ અને એક જ જગ્યાએ તમામ પ્રકારના ડિજિટલ સોલ્યુશન્સ ઑફર કરીએ છીએ, \nનિષ્ણાત કન્સલ્ટન્ટ પાસેથી યોગ્ય કન્સલ્ટન્સી\n",
                    line3:" માત્ર સકારાત્મક સામગ્રીઓ સાથેનું એક પોર્ટલ છે: વીડિયો, સમાચાર અને ઘણું બધું.",
                    line4:"આવો વિશ્વભરમાં સકારાત્મકતા ફેલાવવાના અમારા મિશનમાં સાથે મળીને જોડાઈએ. તમામ શ્રેષ્ઠ!",
                },
                comment: "તમારો અભિપ્રાય આપો",
                name: "નામ",
                email: "ઇમેઇલ",
                mobile: "મોબાઈલ",
                message: "સંદેશ ",
                submit: "સબમિટ કરો",
                readMore: "વધારે વાંચો",
                purushrthaStories: "આદર્શ વાર્તાઓ",
                parmarthStories: "પ્રેરણાદાયક વાર્તાઓ",
                samachar: "સમાચાર",
            },
        }
    }
})