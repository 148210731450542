import { Header } from "./Header";
import { Footer } from "./Footer";
import NewsCard from "./NewsCard";
import { useState, useEffect, useId } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { ADMIN_URL } from "../constants";

function func(news, t) {
  var arr = [];

  for (let index = 0; index < news.length + 1; index = index + 3) {
    var p1 = "";
    var p2 = "";
    var p3 = "";
    if (news[index]) {
      if (news[index].articletype === "P") {
        p1 = t("parmarth");
      } else if (news[index].articletype === "R") {
        p1 = t("purusharth");
      } else {
        p1 = t("positiveNews");
      }
      if (news[index + 1]) {
        if (news[index + 1].articletype === "P") {
          p2 = t("parmarth");
        } else if (news[index + 1].articletype === "R") {
          p2 = t("purusharth");
        } else {
          p2 = t("positiveNews");
        }
      }
      if (news[index + 2]) {
        if (news[index + 2].articletype === "P") {
          p3 = t("parmarth");
        } else if (news[index + 2].articletype === "R") {
          p3 = t("purusharth");
        } else {
          p3 = t("positiveNews");
        }
      }
      arr.push(
        <div className="card-group">
          <NewsCard
            image={news[index].photourl}
            newsid={news[index].id}
            title={news[index].articletitle}
            datetime={p1 + " " + news[index].createddate}
            tags={news[index].tags}
          />

          <NewsCard
            image={index < news.length - 2 ? news[index + 1].photourl : ""}
            newsid={index < news.length - 2 ? news[index + 1].id : ""}
            title={index < news.length - 2 ? news[index + 1].articletitle : ""}
            datetime={
              index < news.length - 2
                ? p2 + " " + news[index + 1].createddate
                : ""
            }
            tags={index < news.length - 2 ? news[index + 1].tags : ""}
          />
          <NewsCard
            image={index < news.length - 2 ? news[index + 2].photourl : ""}
            newsid={index < news.length - 2 ? news[index + 2].id : ""}
            title={index < news.length - 2 ? news[index + 2].articletitle : ""}
            datetime={
              index < news.length - 2
                ? p3 + " " + news[index + 2].createddate
                : ""
            }
            tags={index < news.length - 2 ? news[index + 2].tags : ""}
          />
        </div>
      );
    }
  }
  return arr;
}

export function Filter() {
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  let articleTag = searchParams.get("tag");

  useEffect(() => {
    const res = fetch(
      ADMIN_URL +
        "/getNewsByTags?lang=" +
        i18n.language +
        "&tag=" +
        articleTag +
        "&offset=" +
        offset
    )
      .then((response) => response.json())
      .then((json) => setData((prev) => [...prev, ...json]))
      .catch((error) => console.error("Error: " + error));
    setLoading(false);
    // console.log(json);
    // const jsonData = res.json();
    // setData((prev) => [...prev,...jsonData]);
  }, [offset]);

  useEffect(() => {
    fetch(
      ADMIN_URL +
        "/getNewsByTags?lang=" +
        i18n.language +
        "&tag=" +
        articleTag +
        "&offset=0"
    )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => console.error("Error: " + error));
  }, [i18n, i18n.language, articleTag]);

  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 5 >=
        document.documentElement.scrollHeight
      ) {
        setLoading(true);
        setOffset((prev) => prev + 15);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid py-3" key={useId()}>
        <div className="container">
          <div className="bg-light   py-2 px-4 mb-3 main-border-bottom">
            <span className="redheader-large">
              {articleTag === "N" ? t("positiveNews") : ""}{" "}
            </span>
            <span className="redheader-large">
              {articleTag === "P" ? t("parmarthStories") : ""}{" "}
            </span>
            <span className="redheader-large">
              {articleTag === "R" ? t("purushrthaStories") : ""}{" "}
            </span>
            <p className="description-small m-0">{t("latestDesc")}</p>
          </div>
          {null !== data ? func(data, t) : ""}
        </div>
      </div>
      <Footer />
    </>
  );
}
