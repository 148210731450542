import { Footer } from "./Footer";
import { Header } from "./Header";
import NewsCard from "./NewsCard";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { ADMIN_URL } from "../constants";

function func(news, t) {
  var arr = [];

  for (let index = 0; index < news.length + 1; index = index + 3) {
    var p1 = "";
    var p2 = "";
    var p3 = "";

    if (news[index]) {
      if (news[index].articletype === "P") {
        p1 = t("parmarth");
      } else if (news[index].articletype === "R") {
        p1 = t("purusharth");
      } else {
        p1 = t("positiveNews");
      }
      if (news[index + 1]) {
        if (news[index + 1].articletype === "P") {
          p2 = t("parmarth");
        } else if (news[index + 1].articletype === "R") {
          p2 = t("purusharth");
        } else {
          p2 = t("positiveNews");
        }
      }

      if (news[index + 2]) {
        if (news[index + 2].articletype === "P") {
          p3 = t("parmarth");
        } else if (news[index + 2].articletype === "R") {
          p3 = t("purusharth");
        } else {
          p3 = t("positiveNews");
        }
      }
      arr.push(
        <div className="card-group">
          <NewsCard
            image={news[index].photourl}
            newsid={news[index].id}
            title={news[index].articletitle}
            titlekeywords={news[index].titlekeywords}
            datetime={p1 + " " + news[index].createddate}
            tags={news[index].tags}
          />

          <NewsCard
            image={index < news.length - 2 ? news[index + 1].photourl : ""}
            newsid={index < news.length - 2 ? news[index + 1].id : ""}
            title={index < news.length - 2 ? news[index + 1].articletitle : ""}
            titlekeywords={
              index < news.length - 2 ? news[index + 1].titlekeywords : ""
            }
            datetime={
              index < news.length - 2
                ? p2 + " " + news[index + 1].createddate
                : ""
            }
            tags={index < news.length - 2 ? news[index + 1].tags : ""}
          />
          <NewsCard
            image={index < news.length - 2 ? news[index + 2].photourl : ""}
            newsid={index < news.length - 2 ? news[index + 2].id : ""}
            title={index < news.length - 2 ? news[index + 2].articletitle : ""}
            titlekeywords={
              index < news.length - 2 ? news[index + 2].titlekeywords : ""
            }
            datetime={
              index < news.length - 2
                ? p3 + " " + news[index + 2].createddate
                : ""
            }
            tags={index < news.length - 2 ? news[index + 2].tags : ""}
          />
        </div>
      );
    }
  }
  return arr;
}

export function Body() {
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (offset > 0) {
      const res = fetch(
        ADMIN_URL + "/getNews?lang=" + i18n.language + "&offset=" + offset
      )
        .then((response) => response.json())
        .then((json) => setData((prev) => [...prev, ...json]))
        .catch((error) => console.error("Error: " + error));
      setLoading(false);
    }
    // console.log(json);
    // const jsonData = res.json();
    // setData((prev) => [...prev,...jsonData]);
  }, [offset]);

  useEffect(() => {
    console.log("Called Language");
    const res = fetch(
      ADMIN_URL + "/getNews?lang=" + i18n.language + "&offset=0"
    )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => console.error("Error: " + error));
    setLoading(false);
    // const jsonData = res.json();
    // setData((prev) => [...prev,...jsonData]);
  }, [i18n, i18n.language]);

  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 5 >=
        document.documentElement.scrollHeight
      ) {
        setLoading(true);
        setOffset((prev) => prev + 15);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid py-3">
        <div className="container">
          <div className="bg-light   py-2 px-4 mb-3 main-border-bottom">
            <span className="redheader-large">{t("positiveNews")} </span>
            <p className="description-small m-0">{t("latestDesc")}</p>
          </div>
          {null !== data ? func(data, t) : ""}
          {loading && "Loading..."}
        </div>
      </div>
      <Footer />
    </>
  );
}
