import React from "react";
import { Link } from "react-router-dom";
import { SITE_URL } from "../constants";

export default function NewsCard(props) {
  const newsId = props.newsid;
  return (
      <div className="card" key={props.newsid}>
        <Link to={SITE_URL+"/details?title="+props.titlekeywords+"&articleId="+newsId} >
          <img src={props.image} className="card-img-top" alt="..." />
        </Link>
        <div className="card-body">
          <h5 className="card-title">
            <Link
              className="h6 m-0 font-weight-bold"
              to={SITE_URL+"/details?title="+props.titlekeywords+"&articleId="+newsId}                
            >
              {props.title}
            </Link>
          </h5>
          <p className="card-text">
            <small className="text-muted">{props.datetime}</small>
          </p>
          <Link to={SITE_URL+"/filter?tag="+props.tags}><span className="border border-4 border-primary rounded-pill p-1">
            <small className="text-muted font-weight-bold">
              {props.tags}
            </small>
          </span></Link>
        </div>
      </div>
  );
}
