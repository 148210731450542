import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SITE_URL } from "../constants";

export function Footer() {
  const { t } = useTranslation();
  return (
    <>
      <div className="container-fluid bg-light pt-5 px-sm-3 px-md-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-5">
              <Link to="http://gatagam.in" className="navbar-brand">
                <h1 className="mb-2 mt-n2 display-5  ">
                  <span className="text-primary"> {t("gata")}</span>
                  {t("gam")}
                </h1>
              </Link>
              <p>{t("footerDesc")}</p>
              <div className="d-flex justify-content-start mt-4">
                {/*                     <Link class="btn btn-outline-secondary text-center mr-2 px-0" style="width: 38px; height: 38px;" to="#"><i class="fab fa-twitter"></i></Link> 
              <Link class="btn btn-outline-secondary text-center mr-2 px-0" style="width: 38px; height: 38px;" to="#"><i class="fab fa-facebook-f"></i></Link>
              <Link class="btn btn-outline-secondary text-center mr-2 px-0" style="width: 38px; height: 38px;" to="#"><i class="fab fa-linkedin-in"></i></Link>
              <Link class="btn btn-outline-secondary text-center mr-2 px-0" style="width: 38px; height: 38px;" to="#"><i class="fab fa-instagram"></i></Link>
              <Link class="btn btn-outline-secondary text-center mr-2 px-0" style="width: 38px; height: 38px;" to="#"><i class="fab fa-youtube"></i></Link>*/}
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5">
              <h4 className="font-weight-bold mb-4">{t("category")} </h4>
              <div className="d-flex flex-wrap m-n1">
                <Link
                  to={SITE_URL+"/Category?name=N"}
                  className="btn btn-sm btn-outline-secondary m-1"
                >
                  {t("positiveNews")}
                </Link>
                <Link
                  to={SITE_URL+"/Category?name=P"}
                  className="btn btn-sm btn-outline-secondary m-1"
                >
                  {t("parmarth")}
                </Link>
                <Link
                  to={SITE_URL+"/Category?name=R"}
                  className="btn btn-sm btn-outline-secondary m-1"
                >
                  {t("purusharth")}
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5">
              <h4 className="font-weight-bold mb-4">{t("tags")}</h4>
              <div className="d-flex flex-wrap m-n1">
                <Link to="" className="btn btn-sm btn-outline-secondary m-1">
                  {t("videos")}
                </Link>
                <Link to="" className="btn btn-sm btn-outline-secondary m-1">
                  {t("stories")}
                </Link>
                {/*                     <Link to="" class="btn btn-sm btn-outline-secondary m-1">Banners</Link>*/}
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5">
              <h4 className="font-weight-bold mb-4">{t("quickContact")}</h4>
              <div className="d-flex flex-column justify-content-start">
                <Link
                  className="text-secondary mb-2"
                  to={SITE_URL+"/About"}
                >
                  <i className="fa fa-angle-right text-dark mr-2" />
                  {t("aboutUs")}
                </Link>
                <Link className="text-secondary mb-2" to="#">
                  <i className="fa fa-angle-right text-dark mr-2" />
                  {t("advertisement")}
                </Link>
                <Link className="text-secondary mb-2" to="#">
                  <i className="fa fa-angle-right text-dark mr-2" />
                  {t("privacyPolicy")}
                </Link>
                <Link className="text-secondary mb-2" to="#">
                  <i className="fa fa-angle-right text-dark mr-2" />
                  {t("termsAndConditions")}
                </Link>
                <Link
                  className="text-secondary"
                  to={SITE_URL+"/Contact"}
                >
                  <i className="fa fa-angle-right text-dark mr-2" />
                  {t("contact")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
