import { useTranslation } from 'react-i18next';
import { SITE_URL } from '../constants';
import { useNavigate } from 'react-router-dom';
import { Body } from './Body';

const languages = [
    {code:"en",lang:"English"},
    {code:"hi",lang:"Hindi"},
    {code:"gu",lang:"Gujarati"},
];


const LanguageSelector = () => {
  const navigate = useNavigate();
  const {i18n} = useTranslation();

  
const ChangeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  navigate("/");
};



  return (
    <div>
        {
            languages.map((lng) => {
                return <p className="btn btn-sm btn-outline-secondary m-1" key={lng.code} onClick={()=>ChangeLanguage(lng.code)}>{lng.lang}</p>
            })
        }
    </div>
  )
}

export default LanguageSelector;