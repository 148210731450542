import { Footer } from "./Footer";
import { Header } from "./Header";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function About() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <pre>
                {" "}
                <h1>{t("aboutUs")} </h1>
                {t("about.line1")} {"\t"}
                {"\n"}
                {t("about.line2")}
                {"\n"}
                <Link to="">gatagam.in</Link>
                {t("about.line3")}. {"\n"}                
                {t("about.line4")}
              </pre>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
