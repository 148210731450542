import "./App.css";
import { Body } from "./Componets/Body";
import { Routes, Route } from "react-router-dom";
import Details from "./Componets/Details";
import { About } from "./Componets/About";
import { Contact } from "./Componets/Contact";
import { Category } from "./Componets/Category";
import { Filter } from "./Componets/Filter";

function App() {

  return (
    <div className="App">
      <Routes>
        <Route index element={<Body />} />
        <Route path="about" element={<About />} />
        <Route path="category" element={<Category />} />
        <Route path="filter" element={<Filter />} />
        <Route path="contact" element={<Contact />} />
        <Route path="details" element={<Details />} />
      </Routes>
    </div>
  );
}

export default App;
