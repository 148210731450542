import { Header } from "./Header";
import { Footer } from "./Footer";
import { useTranslation } from "react-i18next";

export function Contact() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <pre>
                <h1>{t("contact")} {t("us")}</h1>
                {"\n"}+91-786-203-0126{"\n"}connect.gatagam@gmail.com
              </pre>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
