import { Link, useSearchParams } from "react-router-dom";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ADMIN_URL, SITE_URL } from "../constants";
import { WhatsappShareButton } from "react-share";
import i18n from "i18next";
import { Helmet } from "react-helmet";

function func(news,t) {
  var arr = [];

  for (let index = 0; index < news.length + 1; index++) {
    if (news[index]) {
      arr.push(
        <div
          className="position-relative overflow-hidden mb-3"
          style={{ height: 80 }}
          key={news[index].id}
        >
          <div className="d-flex mb-3">
            <div
              className="w-100 d-flex flex-column justify-content-center bg-light px-3"
              style={{ height: 80 }}
            >
              <div className="mb-1" style={{ fontSize: 10 }}>
                <Link className="text-white" to="">
                  પોઝિટિવ ન્યૂઝ ;
                </Link>
                <Link className="text-white" to="">
                  <span className="px-1">/</span>
                  2024-03-02 08:39:30
                </Link>
              </div>
              <span className="text-black m-0" style={{ fontSize: 12 }}>
                {news[index].articletitle}
              </span>
              <div className="mb-1" style={{ fontSize: 10 }}>
                <Link
                  to={SITE_URL+"/details?title="+news[index].titlekeywords+"&articleId="+news[index].id}
                  className="btn btn-link readmore-link rightfloat"
                >
                  {t("readMore")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  return arr;
}

function Details() {
  const [data, setData] = useState(null);
  const [realetedData, setRealetedData] = useState([]);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  let newsId = searchParams.get("articleId");
  useEffect(() => {
    fetch(ADMIN_URL + `/getNews?articleId=${newsId}`)
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => console.error("Error: " + error));
  }, [newsId]);

  useEffect(() => {
    fetch(
      ADMIN_URL+`/getRelatedNews?lang=` +
        i18n.language +
        `&articleId=${newsId}`
    )
      .then((response) => response.json())
      .then((json) => setRealetedData(json))
      .catch((error) => console.error("Error: " + error));
  }, [newsId]);

  return (
    <div className="App">
      <Helmet>
         <meta property="og:title" content={(null !== data && "" !== data.articletitle) ? data.articletitle+" | GataGam - Spreading Positivity" : ""} />
         <meta property="og:description" content={(null !== data && "" !== data.articletext) ? data.articletext : ""} />
         <meta property="og:image" content={(null !== data && "" !== data.photourl) ? data.photourl:""} />
       </Helmet>
      <Header />
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {/* News Detail Start */}
              <div className="position-relative mb-3">
                <img
                  alt=""
                  className="img-fluid w-100"
                  src={null !== data ? data.photourl : ""}
                  style={{ objectFit: "cover" }}
                />
                <div className="overlay position-relative bg-light">
                  <div className="mb-3" >
                    <Link to="#">{t("positiveNews")}</Link>
                    <span className="px-1">/</span>
                    <span>{null !== data ? data.lastmodifieddate : ""}</span>
                    <span><WhatsappShareButton
                  url={SITE_URL + "/details?" + searchParams}
                  title={""}
                >
                  <img src={SITE_URL+"/img/whatsapp_3670051.png"} style={{marginLeft: "1450%"}} height={25} width={25} alt="Share on WhatsApp" />
                </WhatsappShareButton></span>
                  </div>
                  <div>
                    <h3 className="mb-3">
                      {null !== data ? data.articletitle : ""}
                    </h3>
                    {null !== data ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.articletext,
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {/* News Detail End */}
              {/* News Detail Start */}
              <div className="position-relative mb-3">
              {console.log(data)}
                  <img
                    className="img-fluid w-100"
                    src={(null !== data && "" !== data.photourl1) ? data.photourl1 : "#"}
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
              {(null !== data && "" !== data.articletext1) ? (
                
                  <div className="overlay position-relative bg-light">
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.articletext1,
                        }}
                      ></div>
                    </div>
                  </div>
              ) : (
                ""
              )}
              
              </div>
              {/* News Detail End */}
              {/* News Detail Start */}
                <div className="position-relative mb-3">
                  <img
                    className="img-fluid w-100"
                    src={(null !== data && "" !== data.photourl2) ? data.photourl2 : "#"}
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
              {(null !== data && "" !== data.articletext2) ? (
                  <div className="overlay position-relative bg-light">
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.articletext2,
                        }}
                      ></div>
                    </div>
                  </div>
              ) : (
                ""
              )}
                </div>
              {/* News Detail End */}
              {/* Comment List Start */}
              {/*<div className="bg-light mb-3" style={{ padding: 30 }}>
                
                  <h3 class="mb-4">0 ટિપ્પણીઓ</h3>
                  
                  
                  <!-- <div class="media">
                      <img src="resources/img/user.jpg" alt="Image" class="img-fluid mr-3 mt-1" style="width: 45px;">
                      <div class="media-body">
                          <h6><Link to="">John Doe</Link> <small><i>01 Jan 2045 at 12:00pm</i></small></h6>
                          <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor labore
                              accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed sed eirmod ipsum.
                              Gubergren clita aliquyam consetetur sadipscing, at tempor amet ipsum diam tempor
                              consetetur at sit.</p>
                          <button class="btn btn-sm btn-outline-secondary">Reply</button>
                          <div class="media mt-4">
                              <img src="resources/img/user.jpg" alt="Image" class="img-fluid mr-3 mt-1"
                                  style="width: 45px;">
                              <div class="media-body">
                                  <h6><Link to="">John Doe</Link> <small><i>01 Jan 2045 at 12:00pm</i></small></h6>
                                  <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor
                                      labore accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed sed
                                      eirmod ipsum. Gubergren clita aliquyam consetetur sadipscing, at tempor amet
                                      ipsum diam tempor consetetur at sit.</p>
                                  <button class="btn btn-sm btn-outline-secondary">Reply</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>*/}
              {/* Comment List End */}
              {/* Comment Form Start */}
              <div className="bg-light mb-3" style={{ padding: 30 }}>
                <h3 className="mb-4"> {t("comment")}</h3>
                <form
                  name="articleCommentForm"
                  id="articleCommentForm"
                  method="POST"
                  action="commentrouting.jsp?action=add_comment"
                  onsubmit="return docvalidateform()"
                >
                  <div className="form-group">
                    <label htmlFor="name">{t("name")} *</label>
                    <input
                      type="text"
                      required=""
                      className="form-control"
                      id="name"
                      name="name"
                    />
                    <input
                      type="hidden"
                      required=""
                      className="form-control"
                      id="articleNumber"
                      name="articleNumber"
                      defaultValue={3284}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">{t("email")} *</label>
                    <input
                      type="email"
                      required=""
                      className="form-control"
                      id="email"
                      name="email"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">{t("mobile")}</label>
                    <input
                      type="number"
                      className="form-control"
                      pattern="[0-9]{10}"
                      id="mobile"
                      name="mobile"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">{t("message")} *</label>
                    <textarea
                      id="message"
                      required=""
                      cols={30}
                      rows={5}
                      name="message"
                      className="form-control"
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-group mb-0">
                    <input
                      type="submit"
                      value={t("submit")}
                      className="btn btn-primary font-weight-semi-bold py-2 px-3"
                    />
                  </div>
                </form>
              </div>
              {/* Comment Form End */}
            </div>
            <div className="col-lg-4 pt-3 pt-lg-0">
              {/* Social Follow Start */}
              {/*  <div class="pb-3">
                  <div class="bg-light py-2 px-4 mb-3">
                      <h3 class="m-0">Follow Us</h3>
                  </div>
                  <div class="d-flex mb-3">
                      <Link to="" class="d-block w-50 py-2 px-3 text-white text-decoration-none mr-2" style="background: #39569E;">
                          <small class="fab fa-facebook-f mr-2"></small><small>12,345 Fans</small>
                      </Link>
                      <Link to="" class="d-block w-50 py-2 px-3 text-white text-decoration-none ml-2" style="background: #52AAF4;">
                          <small class="fab fa-twitter mr-2"></small><small>12,345 Followers</small>
                      </Link>
                  </div>
                  <div class="d-flex mb-3">
                      <Link to="" class="d-block w-50 py-2 px-3 text-white text-decoration-none mr-2" style="background: #0185AE;">
                          <small class="fab fa-linkedin-in mr-2"></small><small>12,345 Connects</small>
                      </Link>
                      <Link to="" class="d-block w-50 py-2 px-3 text-white text-decoration-none ml-2" style="background: #C8359D;">
                          <small class="fab fa-instagram mr-2"></small><small>12,345 Followers</small>
                      </Link>
                  </div>
                  <div class="d-flex mb-3">
                      <Link to="" class="d-block w-50 py-2 px-3 text-white text-decoration-none mr-2" style="background: #DC472E;">
                          <small class="fab fa-youtube mr-2"></small><small>12,345 Subscribers</small>
                      </Link>
                      <Link to="" class="d-block w-50 py-2 px-3 text-white text-decoration-none ml-2" style="background: #1AB7EA;">
                          <small class="fab fa-vimeo-v mr-2"></small><small>12,345 Followers</small>
                      </Link>
                  </div>
              </div> */}
              {/* Social Follow End */}
              {/* Newsletter Start */}
              {/* <div class="pb-3">
                  <div class="bg-light py-2 px-4 mb-3">
                      <h3 class="m-0">Newsletter</h3>
                  </div>
                  <div class="bg-light text-center p-4 mb-3">
                      <p>Aliqu justo et labore at eirmod justo sea erat diam dolor diam vero kasd</p>
                      <div class="input-group" style="width: 100%;">
                          <input type="text" class="form-control form-control-lg" placeholder="Your Email">
                          <div class="input-group-append">
                              <button class="btn btn-primary">Sign Up</button>
                          </div>
                      </div>
                      <small>Sit eirmod nonumy kasd eirmod</small>
                  </div>
              </div>*/}
              {/* Newsletter End */}
              {/* Ads Start */}
              {/* <div class="mb-3 pb-3">
                  <Link to=""><img class="img-fluid" src="resources/img/news-500x280-4.jpg" alt=""></Link>
              </div> */}
              {/* Ads End */}
              {/* Popular News Start */}
              <div className="pb-3">
              {null!==realetedData?func(realetedData,t):''}
                
              <Link to="https://www.youtube.com/c/Happy2Help4u" target="_blank"><img src={SITE_URL+"/img/video_11515830.png"} height={25} alt=""></img></Link> &nbsp;
              <Link to="https://www.instagram.com/gatagam.in?igsh=eGwzMTB4a253cnN4" target="_blank"><img src={SITE_URL+"/img/instagram_174855.png"} height={25} alt=""></img></Link>
                
                {/* <div class="bg-light py-2 px-4 mb-3">
                      <h3 class="m-0">Tranding</h3>
                  </div>
                  <div class="d-flex mb-3">
                      <img src="resources/img/news-100x100-1.jpg" style="width: 100px; height: 100px; object-fit: cover;">
                      <div class="w-100 d-flex flex-column justify-content-center bg-light px-3" style="height: 100px;">
                          <div class="mb-1" style="font-size: 13px;">
                              <Link to="">Technology</Link>
                              <span class="px-1">/</span>
                              <span>January 01, 2045</span>
                          </div>
                          <Link class="h6 m-0" to="">Lorem ipsum dolor sit amet consec adipis elit</Link>
                      </div>
                  </div>
                  <div class="d-flex mb-3">
                      <img src="resources/img/news-100x100-2.jpg" style="width: 100px; height: 100px; object-fit: cover;">
                      <div class="w-100 d-flex flex-column justify-content-center bg-light px-3" style="height: 100px;">
                          <div class="mb-1" style="font-size: 13px;">
                              <Link to="">Technology</Link>
                              <span class="px-1">/</span>
                              <span>January 01, 2045</span>
                          </div>
                          <Link class="h6 m-0" to="">Lorem ipsum dolor sit amet consec adipis elit</Link>
                      </div>
                  </div>
                  <div class="d-flex mb-3">
                      <img src="resources/img/news-100x100-3.jpg" style="width: 100px; height: 100px; object-fit: cover;">
                      <div class="w-100 d-flex flex-column justify-content-center bg-light px-3" style="height: 100px;">
                          <div class="mb-1" style="font-size: 13px;">
                              <Link to="">Technology</Link>
                              <span class="px-1">/</span>
                              <span>January 01, 2045</span>
                          </div>
                          <Link class="h6 m-0" to="">Lorem ipsum dolor sit amet consec adipis elit</Link>
                      </div>
                  </div>
                  <div class="d-flex mb-3">
                      <img src="resources/img/news-100x100-4.jpg" style="width: 100px; height: 100px; object-fit: cover;">
                      <div class="w-100 d-flex flex-column justify-content-center bg-light px-3" style="height: 100px;">
                          <div class="mb-1" style="font-size: 13px;">
                              <Link to="">Technology</Link>
                              <span class="px-1">/</span>
                              <span>January 01, 2045</span>
                          </div>
                          <Link class="h6 m-0" to="">Lorem ipsum dolor sit amet consec adipis elit</Link>
                      </div>
                  </div>
                  <div class="d-flex mb-3">
                      <img src="resources/img/news-100x100-5.jpg" style="width: 100px; height: 100px; object-fit: cover;">
                      <div class="w-100 d-flex flex-column justify-content-center bg-light px-3" style="height: 100px;">
                          <div class="mb-1" style="font-size: 13px;">
                              <Link to="">Technology</Link>
                              <span class="px-1">/</span>
                              <span>January 01, 2045</span>
                          </div>
                          <Link class="h6 m-0" to="">Lorem ipsum dolor sit amet consec adipis elit</Link>
                      </div>
                  </div> */}
              </div>
              {/* Popular News End */}
              {/* Tags Start */}
              {/* <div class="pb-3">
                  <div class="bg-light py-2 px-4 mb-3">
                      <h3 class="m-0">Tags</h3>
                  </div>
                  <div class="d-flex flex-wrap m-n1">
                      <Link to="" class="btn btn-sm btn-outline-secondary m-1">Politics</Link>
                      <Link to="" class="btn btn-sm btn-outline-secondary m-1">Business</Link>
                      <Link to="" class="btn btn-sm btn-outline-secondary m-1">Corporate</Link>
                      <Link to="" class="btn btn-sm btn-outline-secondary m-1">Sports</Link>
                      <Link to="" class="btn btn-sm btn-outline-secondary m-1">Health</Link>
                      <Link to="" class="btn btn-sm btn-outline-secondary m-1">Education</Link>
                      <Link to="" class="btn btn-sm btn-outline-secondary m-1">Science</Link>
                      <Link to="" class="btn btn-sm btn-outline-secondary m-1">Technology</Link>
                      <Link to="" class="btn btn-sm btn-outline-secondary m-1">Foods</Link>
                      <Link to="" class="btn btn-sm btn-outline-secondary m-1">Entertainment</Link>
                      <Link to="" class="btn btn-sm btn-outline-secondary m-1">Travel</Link>
                      <Link to="" class="btn btn-sm btn-outline-secondary m-1">Lifestyle</Link>
                  </div>
              </div> */}
              {/* Tags End */}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Details;
